@font-face {
  font-family: 'FixelDisplay';
  src: url('./assets/fonts/FixelDisplay-Regular.otf') format('opentype');
  font-weight: 400; /* Set normal weight to 400 */
  font-style: normal;
}

@font-face {
  font-family: 'FixelBold';
  src: url('./assets/fonts/FixelDisplay-Bold.otf') format('opentype');
  font-weight: 700; /* Set bold weight to 700 */
  font-style: normal;
}

@font-face {
  font-family: 'FixelLight';
  src: url('./assets/fonts/FixelDisplay-Light.otf') format('opentype');
  font-weight: 300; /* Use 300 for light font */
  font-style: normal;
}

@font-face {
  font-family: 'FixelSemiBold';
  src: url('./assets/fonts/FixelDisplay-SemiBold.otf') format('opentype');
  font-weight: 600; /* Use 600 for semibold */
  font-style: normal;
}

@font-face {
  font-family: 'FixelMedium';
  src: url('./assets/fonts/FixelDisplay-Medium.otf') format('opentype');
  font-weight: 500; /* Use 500 for medium */
  font-style: normal;
}


body {
  font-family: 'FixelBold' !important;
}

